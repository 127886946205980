import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Navigation {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-nav',
            rootToggleAttr: 'rootToggle',
            scrollerAttr: 'scroller',
            toggleAttr: 'toggle',
            closeAttr: 'close',
            breakpoint: 1280
        };

        this.settings = Object.assign({}, defaults, options);

        this.$navigation = element;
        this.$navigationToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');
        this.$navigationScroller = this.$navigation.querySelector('[' + this.settings.initAttr + '="' + this.settings.scrollerAttr + '"]');
        this.$navigationToggles = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$navigationMainWithChildren = this.$navigation.querySelectorAll('.main-nav__list-item.has--children');
        this.$body = document.querySelector('body');
        this.closeTimer = null;

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        const breakpoint = window.matchMedia('(min-width: ' + this.settings.breakpoint + 'px)');

        this.$navigation.addEventListener('change', (e) => {
            const $element = e.target;

            if ($element === this.$navigationToggle) {
                if (this.$navigationToggle.checked === true) {
                    this.clearAllSubnavs();
                    disableBodyScroll(this.$navigationScroller);
                } else {
                    enableBodyScroll(this.$navigationScroller);
                }
            }

            if ($element.getAttribute(this.settings.initAttr) === this.settings.toggleAttr) {
                this.$navigationScroller.scrollTo(0, 0);
            }
        });

        for (let i = 0; i < this.$navigationMainWithChildren.length; i++) {
            const $rootItem = this.$navigationMainWithChildren[i];
            const $closeSubnavHandle = $rootItem.querySelector('[' + this.settings.initAttr + '="' + this.settings.closeAttr + '"]');

            $rootItem.addEventListener('mouseenter', () => {
                if (breakpoint.matches) {
                    this.$body.classList.add('nav--open');
                    disableBodyScroll(this.$navigationScroller);
                }
            });

            $rootItem.addEventListener('mouseleave', () => {
                if (breakpoint.matches) {
                    this.$body.classList.remove('nav--open');
                    this.clearAllSubnavs();
                    enableBodyScroll(this.$navigationScroller);
                }
            });

            if ($closeSubnavHandle) {
                $closeSubnavHandle.addEventListener('click', (e) => {
                    if (breakpoint.matches) {
                        this.$body.classList.remove('nav--open');
                        this.closeMainnav($rootItem);
                    }
                });
            }
        }

        document.addEventListener('click', (e) => {
            if (breakpoint.matches && e.target.classList.contains('sub-nav')) {
                const $rootItem = e.target.closest('.main-nav__list-item.has--children');
                this.closeMainnav($rootItem);
            }
        });
    }

    closeMainnav ($item) {
        clearTimeout(this.closeTimer);

        $item.classList.add('inactive');
        this.closeTimer = window.setTimeout(() => {
            $item.classList.remove('inactive');
        }, 500);
    }

    clearAllSubnavs () {
        for (let i = 0; i < this.$navigationToggles.length; i++) {
            const $toggle = this.$navigationToggles[i];

            if ($toggle.checked === true) {
                $toggle.checked = false;
            }
        }
    }
}

export default Navigation;
