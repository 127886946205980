import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

const loadLazyImages = () => {
    const pictures = document.querySelectorAll('.lazyload, .lazyloading, .lazyloaded');

    for (let i = 0; i < pictures.length; i++) {
        const picture = pictures[i];
        const image = picture.querySelector('img');

        if (image !== null) {
            image.classList.add('lazyload');
        }
    }

    // add simple support for background images:
    document.addEventListener('lazybeforeunveil', (e) => {
        const bg = e.target.getAttribute('data-bg');
        if (bg) {
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });
};

export { loadLazyImages };
