import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { getOffset, smoothScrollTo } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import SiteHeader from './layout/siteheader/siteheader';
import Navigation from './layout/navigation/navigation';

const initContent = ($content) => {
    console.log('start app');

    const $$anchor = $content.querySelectorAll('a[href*="#"]');
    for (let i = 0; i < $$anchor.length; i++) {
        const $anchor = $$anchor[i];

        $anchor.addEventListener('click', (e) => {
            let baseUrl = $anchor.getAttribute('href').split('#')[0];
            const targetId = $anchor.getAttribute('href').split('#')[1];
            const $target = document.getElementById(targetId);
            const currentUrl = window.location.href;

            if (baseUrl === '') {
                baseUrl = currentUrl;
            }

            if (baseUrl === currentUrl && $target !== null) {
                const anchorDimensions = getOffset($target);
                const anchorDimensionsTop = anchorDimensions.top;

                smoothScrollTo(0, anchorDimensionsTop, window.OPTIONS.speed);
                e.preventDefault();
            }
        });
    }

    if (typeof window.Modernizr !== 'undefined') {
        if (!window.Modernizr.csshover) {
            const $hovers = $content.querySelectorAll('[data-hover]');
            const hoverClass = 'hover';

            if ($hovers.length > 0) {
                for (let i = 0; i < $hovers.length; i++) {
                    const $hover = $hovers[i];

                    $hover.addEventListener('touchstart', () => {
                        if (!$hover.classList.contains(hoverClass)) {
                            $hover.classList.add(hoverClass);
                        }
                    }, { passive: true });
                }

                document.addEventListener('touchstart', (e) => {
                    const $hoveredElements = $content.getElementsByClassName(hoverClass);

                    for (let i = 0; i < $hoveredElements.length; i++) {
                        const $currentHover = $hoveredElements[i];
                        const isClickInside = $currentHover.contains(e.target);

                        if (!isClickInside) {
                            if ($currentHover.classList.contains(hoverClass)) {
                                $currentHover.classList.remove(hoverClass);
                            }
                        }
                    }
                }, { passive: true });
            }
        }
    }

    loadLazyImages();
};

const handleUsercentrics = () => {
    const maxTries = 100;
    let currentTry = 0;

    const addStyleTag = ($host) => {
        const $shadowRoot = $host.shadowRoot;

        const $styleTag = document.createElement('style');
        let styleInformation = '';
        styleInformation = styleInformation + '\n.liwSgQ { min-height: auto; border-radius: 8px 8px 0 0; box-shadow: rgb(0 0 0 / 22%) 0px 5px 26px 0px, rgb(0 0 0 / 30%) 0px 20px 28px 0px; }';
        styleInformation = styleInformation + '\n#uc-show-more font { font-family: "Asap Condensed", Arial, sans-serif !important; } button[data-testid] { z-index: 2}';
        $styleTag.innerHTML = styleInformation;

        $shadowRoot.appendChild($styleTag);
    };

    const userCentricsInterval = window.setInterval(() => {
        const $usercentrics = document.getElementById('usercentrics-root');
        if ($usercentrics || currentTry === maxTries) {
            clearInterval(userCentricsInterval);
            if ($usercentrics) {
                addStyleTag($usercentrics);
            }
        }
        currentTry = currentTry + 1;
    }, 100);
};

domready(function () {
// document.addEventListener('DOMContentLoaded', () => {
    // GLOBAL OPTIONS
    window.OPTIONS = {
        speed: 500,
        breakpoints: {
            s: 320,
            m: 768,
            l: 1024,
            xl: 1280,
            xxl: 1440,
            xxxl: 1920
        },
        loader: '<b class="loading"><b class="loading__loader"><i>loading...</i></b></b>'
    };

    window.eventScroller = new EventScroller();
    window.resizeHandler = new ResizeHandler();

    if (document.body.getAttribute('data-animate') === null) {
        window.allowAnimation = false;
    } else {
        window.allowAnimation = true;
    }

    window.checkInview = (scroller, htmlAttribute = '[data-inview]:not(.inview)') => {
        const $inviews = document.querySelectorAll(htmlAttribute);

        for (let i = 0; i < $inviews.length; i++) {
            const $inview = $inviews[i];
            const onScreenStatus = scroller.onScreen($inview, 100);

            if (!$inview.classList.contains('inview') && onScreenStatus === true) {
                $inview.classList.add('inview');
            }
        }
    };

    const initApplication = () => {
        handleUsercentrics();

        window.eventScroller.init();
        window.resizeHandler.init();

        const $siteheader = document.querySelector('[data-siteheader="root"]');
        if ($siteheader) {
            const siteheaderAPI = new SiteHeader($siteheader);
            $siteheader.API = siteheaderAPI;
        }

        const $navigation = document.querySelector('[data-nav="root"]');
        if ($navigation) {
            const navigationAPI = new Navigation($navigation);
            $navigation.API = navigationAPI;
        }

        const $breadcrumb = document.querySelector('[data-breadcrumbs="root"]');
        if ($breadcrumb) {
            $breadcrumb.scrollLeft = $breadcrumb.scrollWidth;
        }

        window.setTimeout(() => {
            window.checkInview(window.eventScroller);
            window.eventScroller.customFunctions.push(() => {
                window.checkInview(window.eventScroller);
            });
        }, 750);

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });
        window.setTimeout(() => {
            window.dispatchEvent(contentUpdateEvent);
        }, 10);

        if (!window.location.hash) {
            document.body.classList.add('page--loaded');
        }
        window.addEventListener('beforeunload', () => {
            document.body.classList.remove('page--loaded');
        });

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });

        /*
        const _paq = window._paq = window._paq || [];
        _paq.push(['HeatmapSessionRecording::enable']);
        */
    };

    initApplication();
});

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        initContent($context);

        if (window.location.hash.length > 0) {
            const $target = document.querySelector(window.location.hash);

            if ($target) {
                let anchorDimensionsCurrent = 0;

                const anchorInterval = window.setInterval(() => {
                    const anchorDimensions = getOffset($target);
                    const anchorDimensionsTop = anchorDimensions.top;

                    if (anchorDimensionsCurrent === anchorDimensionsTop) {
                        window.scrollTo(0, anchorDimensionsTop);
                        document.body.classList.add('page--loaded');
                        clearInterval(anchorInterval);

                        history.replaceState(null, null, ' ');
                    } else {
                        anchorDimensionsCurrent = anchorDimensionsTop;
                    }
                }, 150);
            }
        }
    }
});
