class SiteHeader {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-siteheader'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$siteHeader = $element;
        this.eventScroller = window.eventScroller;
        this.currentScrollPosition = window.scrollY;
        this.backScrolling = false;

        window.setTimeout(() => {
            this.initialize();
        }, 100);
    }

    initialize () {
        this.checkPagePosition();
        this.eventScroller.customFunctions.push(() => {
            this.checkPagePosition();
        });
    }

    checkPagePosition () {
        if (window.scrollY > 56) {
            this.$siteHeader.classList.add('is--sticky');
            if (window.scrollDirection === 'up') {
                if (this.backScrolling === false) {
                    this.currentScrollPosition = window.scrollPosition;
                    this.backScrolling = true;
                }
                if (!this.$siteHeader.classList.contains('is--up') && window.scrollPosition <= this.currentScrollPosition - 56) {
                    this.$siteHeader.classList.add('is--up');
                }
            } else {
                if (this.$siteHeader.classList.contains('is--up')) {
                    this.$siteHeader.classList.remove('is--up');
                    this.backScrolling = false;
                }
            }
        } else {
            this.$siteHeader.classList.remove('is--sticky');
            this.$siteHeader.classList.remove('is--up');
        }
    }
}
export default SiteHeader;
